import React from 'react'

export default function Dropdown({
    title = ' ',
    children,
    id,
    onClick,
    isExpanded = false,
}) {

    return (
        <div className='accordion-2__accordion nav-accordion'>
            <input
                id={id}
                onClick={onClick}
                type='checkbox'
                checked={isExpanded}
            />
            <label
                className={
                    `accordion-2__accordion__label accordion-2__accordion__label--default nav-accordion__label`
                }
                htmlFor={id}
            >
                {title}
            </label>
            <div
                className='accordion-2__accordion__content nav-accordion__content'
            >
                {children}
            </div>
        </div>
    );
}
