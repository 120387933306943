// react imports
import React, { useState } from 'react';

// third party imports

// in-app imports

const CHAR_LIMIT = 300;

const Button = ({
    helperText = "",
    buttonLabel,
    onClick,
    buttonType,
    buttonSize = 'lg',
    variant = 'square',
    isDisabled = false,
    type = '',
    className = '',
    iconLeft = null,
    iconRight = null,
}) => {
    const textLengthExceeded = helperText?.length > CHAR_LIMIT;
    const [hideText, setHideText] = useState(textLengthExceeded);

    return (
        <div className={`s-button ${className}`}>
            {
                helperText && (
                    <div className='s-button__helper'>
                        <div className={`s-button__text${hideText ? ' s-button__text--hidden' : ''}`}>
                            {helperText}
                        </div>
                        {textLengthExceeded &&
                            <span
                                className='s-button__text s-button__text--link'
                                onClick={() => setHideText(!hideText)}
                            >
                                {hideText ? 'show more' : 'show less'}
                            </span>
                        }
                    </div>
                )
            }
            <button
                className={`s-button__button
                s-button__button--${buttonSize}
                s-button__button--${variant}
                s-button__button--${buttonType}${isDisabled ? '--disabled' : ''}`}
                onClick={onClick}
                disabled={isDisabled}
                type={type}
            >
                <div className='s-button__content'>
                    {iconLeft && <img className='s-button__icon s-button__icon--left' src={iconLeft} alt='' />}
                    {buttonLabel}
                    {iconRight && <img className='s-button__icon s-button__icon--right' src={iconRight} alt='' />}
                </div>
            </button>
        </div>
    );
}

export default Button;
