import React, { useEffect, useState } from 'react'
import { isBrowser } from '../helpers/utils';



export default function Dropdown({
    title = ' ',
    children,
    id,
    isExpanded = false,
    width,
}) {
    const [expanded, setExpanded] = useState(isExpanded);

    const [track, setTrack] = useState({ mouse: false, click: false })


    function handleMouseOver() {
        setTimeout(setTrack((pre) => {
            return {
                ...pre,
                mouse: false
            }
        }), 500)
    }

    useEffect(() => {
        if (width < 786 && track.click || track.mouse) {
            setExpanded(true)
        }
        else {
            setExpanded(false)
        }
    }, [track])

    return (
        <div className='accordion-2__accordion'>
            <input
                id={id}
                type='checkbox'
                checked={width > 786 ? expanded : track.click}
                onChange={() => setTrack((pre) => {
                    return {
                        ...pre,
                        click: !pre.click
                    }
                })}
            />
            <label
                className={
                    `accordion-2__accordion__label accordion-2__accordion__label--default`
                }
                onMouseOver={() => setTrack((pre) => {
                    return {
                        ...pre,
                        mouse: true
                    }
                })}
                onMouseOut={() => handleMouseOver}
                htmlFor={id}
            >
                {title}
            </label>
            <div
                className='accordion-2__accordion__content'
                onMouseOver={() => setTrack((pre) => {
                    return {
                        ...pre,
                        mouse: true
                    }
                })}
                onMouseOut={() => setTrack((pre) => {
                    return {
                        ...pre,
                        mouse: false
                    }
                })}
                
                onClick={() => setExpanded(false)}
            >
                {children}
            </div>
        </div>
    );
}

