import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import Dropdown from '../Dropdown_V1';
import Link from '../Link';

import { HEADER } from '../../cms/home/queryConstants';
import { NAV_DROP, NAV_ITEM } from '../../constants/constants';
import { isBrowser, showButton } from '../../helpers/utils';

const getNav = (item, idx, width, onClick = () => { }) => {
    switch (item?.type) {
        case NAV_ITEM:
            return (
                <li
                    className="header__navbar-item"
                    key={`nav-item-${idx}`}
                >
                    <Link
                        to={item?.link}
                    >
                        {item?.title}
                    </Link>
                </li>
            );
        case NAV_DROP:
            return (
                <li
                    className="header__navbar-item"
                    key={`nav-drop-${idx}`}
                >
                    <Dropdown
                        isExpanded={false}
                        id={idx}
                        title={item?.title}
                        width={width}
                    >
                        <NavbarContent data={item?.options} onClick={onClick} />
                    </Dropdown>
                </li>
            );
    }
}

const Header = () => {
    const [mobile, setMobile] = useState(false)
    const [scrollDirection, setScrollDirection] = useState('');
    const [width, setWidth] = useState(0);

    const { data } = useQuery(HEADER);

    const apiData = data?.header?.data?.attributes;

    const handleDownload = () => {
        window.location.href = window.oneLinkRedirectionLink;
    }

    const handleWidth = () => {
        if (window.innerWidth > 786) {
            setMobile(false)
        }
        setWidth(window.innerWidth)
    }

    useEffect(() => {

        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? 'invisible' : 'sticky';
            if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };

        window.addEventListener('scroll', updateScrollDirection, { passive: true });
        window.addEventListener('resize', handleWidth, { passive: true })
        return () => {
            window.removeEventListener('scroll', updateScrollDirection);
            window.removeEventListener('resize', handleWidth);
        };
    }, []);

    useEffect(() => {
        if (isBrowser()) {
            setWidth(window?.innerWidth)
        }
    }, [])


    return (
        <header
            className={`header header--${scrollDirection}`}
        >
            {mobile ?
                <MobileNav
                    setMobile={setMobile}
                    width={width}
                    apiData={apiData}
                    handleDownload={handleDownload}
                /> :
                <DesktopNav
                    setMobile={setMobile}
                    width={width}
                    apiData={apiData}
                    handleDownload={handleDownload}
                />}
        </header>
    )
}

const NavbarContent = ({ data, onClick }) => {
    return (
        <div
            className='navContent'
        >
            <div className="navContent__container">
                {data?.map((item, idx) => {
                    return (
                        <div
                            className="navContent__item"
                            key={`navContent-item-${idx}`}
                        >
                            <p className="navContent__heading">
                                {item?.heading}
                            </p>
                            {item?.cards?.map((card, iter) => {
                                return (
                                    <div
                                        key={`navContent-card-${iter}`}
                                        className="navContent__card"
                                        onClick={onClick}
                                    >
                                        <Link to={card?.link}>
                                            <div className="navContent__main">
                                                <img src={card?.icon} alt="" className="navContent__icon" />
                                                <p className="navContent__title">
                                                    {card?.title}
                                                </p>
                                            </div>
                                            <p className="navContent__description">
                                                {card?.description}
                                            </p>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


const DesktopNav = ({ setMobile, width, apiData, handleDownload }) => {
    return (
        <div className="header__container" id='top'>
            <Link
                to={apiData?.data?.header_link}
                className={'link-text'}
            >
                <img
                    className='header__logo'
                    src={apiData?.data?.header_logo}
                    alt=''
                />
            </Link>
            <nav className='header__navbar'>
                <ul className='header__navbar-nav'>
                    {apiData?.data?.data?.map((nav, idx) => {
                        return (getNav(nav, idx, width, () => setMobile(false)))
                    })}
                </ul>

            </nav>
            <div className="header__cta-container">
                {width > 768 ?
                    <>
                        <p className="header__cta-text">
                            <Link to={apiData?.cta_left_link}>
                                {apiData?.['cta_left']}
                            </Link>
                        </p>
                        <button className='header__cta'>
                            <Link
                                className='link-text'
                                to={apiData?.desktop_link}
                            >
                                {apiData?.['desktop_text']}
                            </Link>
                        </button>
                    </> :
                    <>
                        {
                            showButton() &&
                            <button
                                className='header__cta'
                                onClick={handleDownload}
                            >
                                {apiData?.['mobile_text']}
                            </button>
                        }
                        <img
                            src={'https://static.zolve.com/zolve-marketing-website/header-toggle-close.webp'}
                            alt=""
                            onClick={() => setMobile(true)}
                            className="header__toggle"
                        />
                    </>
                }
            </div>
        </div>
    )
};

const MobileNav = ({ setMobile, width, apiData, handleDownload }) => {
    return (
        <div className="nav-b">
            <div className="nav-b__container">
                <Link
                    to={apiData?.data?.header_link}
                    className={'link-text'}
                >
                    <img
                        className='header__logo'
                        src={apiData?.data?.header_logo}
                        alt=''
                    />
                </Link>
                <div className="nav-b__cta-container">
                    {
                        showButton() &&
                        <button
                            className='header__cta'
                            onClick={handleDownload}
                        >
                            {apiData?.['mobile_text']}
                        </button>
                    }
                    <img
                        src={'https://static.zolve.com/zolve-marketing-website/header-toggle-open.webp'}
                        alt=""
                        onClick={() => setMobile(false)}
                        className="header__toggle"
                    />

                </div>
            </div>
            <ul className='header__navbar-nav'>
                {apiData?.data?.data?.map((nav, idx) => {
                    return (getNav(nav, idx, width, () => setMobile(false)))
                })}
            </ul>
        </div>
    )
}

export default Header
