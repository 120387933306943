import React from 'react';

import Layout from './src/components/Layout';
import { REDIRECTION_PATHS } from './src/constants/constants';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/style.scss';

export const wrapPageElement = ({ element, props }) => {
    return (
        <Layout {...props}>
            {element}
        </Layout>
    );
}

export const onRouteUpdate = ({ location }) => {
    const pathSplit = location?.pathname?.split('/');
    let redirect = false;
    pathSplit?.forEach(element => {
        if (REDIRECTION_PATHS.includes(element)) redirect = true;
    });

    const searchParams = new URLSearchParams(window.location.search).toString();

    if (redirect) {
        const baseURL = process.env.NODE_ENV === 'development' ? 'stg-products.zolve.com' : 'products.zolve.com'
        const redirectionURL = searchParams ?
            `https://${baseURL}${location?.pathname}?${searchParams}` :
            `https://${baseURL}${location?.pathname}`;
        window.location.replace(redirectionURL);
    }
    setTimeout(() => {
        if (!window?.qr) {
            window.qr = window?.AF_SMART_SCRIPT?.displayQrCode('app-download-qr');
        }
    }, 2000);
}
