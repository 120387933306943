// react imports
import React from 'react';

// third party imports
import { useQuery } from '@apollo/client';

// in-app imports
import Button from './Button';
import ParseHTML from './ParseHTML';
import { COOKIE_CONSENT_QUERY } from '../cms/home/queryConstants';

export default ({
    onCookieConsentClick
}) => {
    const { data } = useQuery(COOKIE_CONSENT_QUERY);

    return (
        <div className="z-cc">
            <div className="z-cc__container">
                <div className="z-cc__text-wrapper">
                    <div className="z-cc__text">
                        {<ParseHTML string={data?.cookieConsent?.data?.attributes?.consentText?.text} />}
                    </div>
                    <div className="z-cc__cta">
                        <Button 
                            buttonLabel={data?.cookieConsent?.data?.attributes?.consentText?.ctaText} 
                            buttonType={"primary"} 
                            onClick={onCookieConsentClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
