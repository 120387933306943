import {
    isBrowser,
    storeRakutenProperties,
    storeUtmProperties
} from '../helpers/utils'

export function initFunnel() {
    if (!isBrowser()) return;
    storeUtmProperties();
    storeRakutenProperties();
}
