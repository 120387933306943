import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { isValidURL } from '../helpers/utils';

const Link = ({ to, children, ...props }) => {
    if (!to) return <></>;
    else if (isValidURL(to)) {
        return (
            <a href={to} {...props}>{children}</a>
        );
    }
    else {
        return (
            <GatsbyLink to={to} {...props}>{children}</GatsbyLink>
        );
    }
}

export default Link;
